import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from '@material-ui/core/Container';


const Page = () => {
  return (
    <Layout>
      <SEO title="Thank You" />
      <Container fixed style={{ minHeight: '100vh' }}>
        <h1>Thank You</h1>
        <p>We will be in touch shortly.</p>
      </Container>
    </Layout>
  )};

export default Page
